import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { ArrowDownSvgComponent } from '@uc/shared/svg';
import { DropdownDirective } from '@uc/utilities';

@Component({
	selector: 'cms-dropdown',
	standalone: true,
	templateUrl: './dropdown.component.html',
	imports: [ArrowDownSvgComponent, DropdownDirective, NgClass],
})
export class DropdownComponent {
	label = input.required<string>();
	customStyles = input<string>();
}
