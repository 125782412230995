export type BadgeTheme =
	| 'blue'
	| 'purple'
	| 'pink'
	| 'green'
	| 'yellow'
	| 'grey'
	| 'indigo'
	| 'red';

export enum BadgeThemeEnum {
	Blue = 'blue',
	Purple = 'purple',
	Pink = 'pink',
	Green = 'green',
	Yellow = 'yellow',
	Grey = 'grey',
	Indigo = 'indigo',
	Red = 'red',
}
