@if (label()) {
	<div
		[ngClass]="selectedTheme()"
		class="rounded-md py-0.5 px-3 flex items-center gap-1 capitalize max-w-max"
	>
		@if (icon()) {
			@switch (label().toLowerCase()) {
				@case ('drafted') {
					<uc-pencil-square-solid-svg />
				}
				@case ('scheduled') {
					<uc-clock-solid-svg customStyles="!text-yellow-800" />
				}
			}
		}
		<span [ngClass]="customStyles()" class="capitalize">{{
			label().toLowerCase()
		}}</span>
	</div>
}
