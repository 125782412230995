import { NgClass } from '@angular/common';
import { Component, input, output } from '@angular/core';

@Component({
	selector: 'cms-close-toastr-btn',
	standalone: true,
	imports: [NgClass],
	templateUrl: './close-toastr-btn.component.html',
})
export class CloseToastrBtnComponent {
	customStyles = input<string>();
	clickHandler = output();

	onClick() {
		this.clickHandler.emit();
	}
}
