<button
	#button
	(click)="onClick()"
	id="dropdown-search-button-{{ id }}"
	class="font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center w-full p-2.5 text-gray-900 border border-gray-300 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 justify-between"
	type="button"
>
	<span class="truncate whitespace-nowrap">{{ selectedItem()?.name ?? label() }}</span>
	<uc-arrow-down-svg customStyles="w-4 h-4 ms-3" />
</button>
<!-- Dropdown menu -->

<div
	#options
	id="dropdown-search-{{ id }}"
	class="absolute z-[103] hidden mt-4 bg-white rounded-lg shadow"
	data-test-id="dropdown-search-options"
>
	<div class="p-3">
		<label for="input-group-search-{{ id }}" class="sr-only">Search</label>
		<div class="relative">
			<div
				class="absolute inset-y-0 flex items-center pointer-events-none start-0 ps-3"
			>
				<uc-search-svg customStyles="w-4 h-4 text-gray-500" />
			</div>
			<input
				[(ngModel)]="searchTerm"
				(keydown)="onKeyDown($event)"
				(input)="onSearch()"
				type="text"
				id="input-group-search-{{ id }}"
				class="block w-full p-2 text-sm text-gray-900 border border-gray-300 rounded-lg ps-10 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
				placeholder="Search"
				data-test-id="dropdown-search-input"
			/>
		</div>
	</div>
	<ul
		class="h-48 px-3 pb-3 overflow-y-auto text-sm text-gray-700"
		attr.aria-labelledby="dropdown-search-button-{{ id }}"
	>
		@for (item of filteredItems; let i = $index; track item.id) {
			<li
				(click)="onSelectItem(item, i)"
				class="flex items-center rounded"
				[ngClass]="{
					'bg-blue-700 !text-white': focusedValueIndex === i,
					'text-gray-900': focusedValueIndex !== i,
				}"
				data-test-id="dropdown-search-item"
			>
				<span
					class="w-full max-w-2xl py-2 text-sm font-medium truncate rounded cursor-pointer ms-2 whitespace-nowrap"
					>{{ item.name }}</span
				>
			</li>
		}
	</ul>
</div>
