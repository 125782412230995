import { Type, WritableSignal, signal } from '@angular/core';

export class SidebarService {
	content: WritableSignal<Type<unknown> | null> = signal(null);
	fixedMenuContent: WritableSignal<Type<unknown> | null> = signal(null);

	setContent(component: Type<unknown> | null) {
		this.content.set(component);
	}

	setFixedMenuContent(component: Type<unknown> | null) {
		this.fixedMenuContent.set(component);
	}
}
