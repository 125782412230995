import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Output,
	ViewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { UserIconSvgComponent, BurgerSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'cms-navbar',
	standalone: true,
	imports: [RouterModule, UserIconSvgComponent, BurgerSvgComponent],
	templateUrl: './navbar.component.html',
})
export class NavbarComponent implements AfterViewInit {
	@ViewChild('navbar') navbar!: ElementRef<HTMLElement>;
	@Output() toggleSidebar = new EventEmitter<void>();

	onToggleSidebar() {
		this.toggleSidebar.emit();
	}

	ngAfterViewInit(): void {
		const height = this.navbar.nativeElement.offsetHeight;
		document.documentElement.style.setProperty('--navbar-height', `${height}px`);
	}
}
