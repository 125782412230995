<nav #navbar class="fixed w-full bg-white z-[100]">
	<div class="flex items-center gap-6 px-4 py-3 border-b md:px-10">
		<a routerLink="/">
			<img
				src="https://cdn.universitycompare.com/assets/img/logo.svg"
				width="40"
				height="40"
				alt="Uni Compare logo"
			/>
		</a>

		<button (click)="onToggleSidebar()" type="button">
			<uc-burger-svg />
		</button>
		<ul class="hidden gap-6 md:flex">
			<li>
				<a
					routerLink="/content"
					class="text-sm font-medium text-gray-500"
					[routerLinkActive]="['!text-blue-500']"
					>Content/SEO</a
				>
			</li>
			<li>
				<a routerLink="/client-success" class="text-sm font-medium text-gray-500"
					>Client Success</a
				>
			</li>
			<li>
				<a routerLink="/campaigns" class="text-sm font-medium text-gray-500"
					>Campaigns</a
				>
			</li>
			<li>
				<a routerLink="/analytics" class="text-sm font-medium text-gray-500"
					>Company Analytics</a
				>
			</li>
		</ul>
		<uc-user-icon-svg class="inline-block ml-auto" customStyles="w-10 h-10" />
	</div>
</nav>
