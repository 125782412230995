<div ucDropdown class="relative" [ngClass]="customStyles()">
	<button
		class="w-full flex items-center justify-between border text-gray-800 focus:ring-2 focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 whitespace-nowrap capitalize"
		type="button"
	>
		{{ label() }}
		<uc-arrow-down-svg customStyles="ms-3" />
	</button>

	<!-- Dropdown menu -->
	<div
		class="absolute right-0 z-10 w-full bg-white divide-y divide-gray-100 rounded-lg shadow-lg top-14 sm:w-44 dropdown-content"
	>
		<div class="py-2 text-sm text-gray-700">
			<ng-content select="[dropdown-options]"></ng-content>
		</div>
	</div>
</div>
