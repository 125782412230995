import {
	AfterViewInit,
	Component,
	ElementRef,
	Type,
	ViewChild,
	ViewContainerRef,
	effect,
	signal,
	untracked,
} from '@angular/core';
import { NgClass } from '@angular/common';
import { ArrowLeftSvgComponent } from '@uc/shared/svg';
import { SidebarService } from './sidebar.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { toSignal } from '@angular/core/rxjs-interop';
import { ScreenWidth } from '@uc/web/shared/data-models';
import { Router, RouterModule } from '@angular/router';
import { NavigationMobileComponent } from './components/navigation-mobile/navigation-mobile.component';

@Component({
	selector: 'cms-sidebar',
	standalone: true,
	templateUrl: './sidebar.component.html',
	exportAs: 'sidebar',
	imports: [NavigationMobileComponent, ArrowLeftSvgComponent, NgClass, RouterModule],
})
export class SidebarComponent implements AfterViewInit {
	@ViewChild('content', { read: ViewContainerRef })
	content!: ViewContainerRef;
	@ViewChild('fixedMenuContent', { read: ViewContainerRef })
	fixedMenuContent!: ViewContainerRef;
	@ViewChild('fixedMenu') fixedMenu!: ElementRef<HTMLElement>;
	@ViewChild('sidebar') sidebar!: ElementRef<HTMLElement>;
	isOpen = signal(false);
	isMobile = toSignal(
		this._breakpointObserver.observe(`(max-width: ${ScreenWidth.SM - 1}px)`),
	);

	constructor(
		private _router: Router,
		private _breakpointObserver: BreakpointObserver,
		private _sidebarSrv: SidebarService,
	) {
		effect(() => {
			const content = this._sidebarSrv.content();
			this._setContent(content);

			const fixedMenuContent = this._sidebarSrv.fixedMenuContent();
			this._setFixedMenuContent(fixedMenuContent);

			untracked(() => {
				if (this.isMobile()?.matches) {
					this.isOpen.set(false);
				} else {
					this.isOpen.set(true);
				}
			});
		});

		effect(() => {
			if (this.isOpen()) {
				const width = this.sidebar.nativeElement.offsetWidth;
				document.documentElement.style.setProperty(
					'--sidebar-width',
					`${width}px`,
				);
			} else {
				document.documentElement.style.setProperty('--sidebar-width', '0');
			}
		});
	}

	ngAfterViewInit(): void {
		const height = this.fixedMenu.nativeElement.offsetHeight;
		document.documentElement.style.setProperty('--sidebar-fixed-menu', `${height}px`);
	}

	onLogout() {
		localStorage.clear();
		this._router.navigate(['/login']);
	}

	onClose() {
		this.isOpen.set(false);
	}

	private _setContent(component: Type<unknown> | null) {
		this.content.clear();
		if (component) {
			this.content.createComponent(component);
		}
	}

	private _setFixedMenuContent(component: Type<unknown> | null) {
		this.fixedMenuContent.clear();
		if (component) {
			this.fixedMenuContent.createComponent(component);
		}
	}
}
