import { Component, input, output } from '@angular/core';

@Component({
	selector: 'cms-options-dropdown-checkbox',
	standalone: true,
	imports: [],
	templateUrl: './options-dropdown-checkbox.component.html',
})
export class OptionsDropdownCheckboxComponent<T> {
	optionId = input.required<string>();
	value = input.required<T>();
	label = input.required<string>();
	checked = input.required<boolean>();
	optionSelected = output<T>();

	onChange(value: T) {
		this.optionSelected.emit(value);
	}
}
