import { Component, input, output } from '@angular/core';

@Component({
	selector: 'cms-pill',
	standalone: true,
	imports: [],
	templateUrl: './pill.component.html',
})
export class PillComponent<T> {
	label = input.required<string>();
	filterId = input.required<T>();
	allowDelete = input<boolean>(true);
	onRemove = output<T>();
}
