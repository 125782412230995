import { Component, input } from '@angular/core';
import { FormErrorComponent } from '@uc/shared/ui';

@Component({
	selector: 'cms-form-error-with-server-error',
	standalone: true,
	imports: [FormErrorComponent],
	templateUrl: './form-error-with-server-error.component.html',
})
export class FormErrorWithServerErrorComponent {
	isSubmitted = input.required<boolean>();
	hasError = input.required<boolean>();
	hasServerError = input.required<boolean>();
	error = input.required<string>();
	serverError = input<string>();
}
