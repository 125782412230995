import { NgClass } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { BadgeTheme } from './content-badge.models';
import {
	PencilSquareSolidComponent,
	ClockSolidSvgComponent,
	CircularCheckmarkSvgComponent,
} from '@uc/shared/svg';

@Component({
	selector: 'cms-content-badge',
	standalone: true,
	templateUrl: './content-badge.component.html',
	imports: [
		NgClass,
		PencilSquareSolidComponent,
		ClockSolidSvgComponent,
		CircularCheckmarkSvgComponent,
	],
})
export class ContentBadgeComponent {
	label = input.required<string>();
	theme = input.required<BadgeTheme | undefined>();
	icon = input(false);
	customStyles = input('');

	selectedTheme = computed(() => {
		switch (this.theme()) {
			case 'blue':
				return 'bg-blue-100 text-blue-800';
			case 'purple':
				return 'bg-purple-100 text-purple-800';
			case 'pink':
				return 'bg-pink-100 text-pink-800';
			case 'green':
				return 'bg-green-100 text-green-800';
			case 'yellow':
				return 'bg-yellow-100 text-yellow-800';
			case 'grey':
				return 'bg-gray-100 text-gray-900';
			case 'indigo':
				return 'bg-indigo-100 text-indigo-800';
			case 'red':
				return 'bg-red-100 text-red-800';
			default:
				return;
		}
	});
}
