<aside
	#sidebar
	class="fixed overflow-y-auto w-64 md:top-[var(--navbar-height)] left-0 md:z-auto z-[102] h-[calc(100%-var(--sidebar-fixed-menu))] transition-all duration-150 border-r bg-white"
	[ngClass]="{ 'translate-x-0': isOpen(), '-translate-x-full': !isOpen() }"
	aria-label="Sidebar"
>
	<!-- Mobile navigation -->
	<cms-navigation-mobile (closeSidebar)="onClose()" />

	<button
		(click)="onClose()"
		class="relative z-50 items-center justify-end hidden w-full gap-2 px-3 py-4 font-medium text-gray-500 bg-white md:inline-flex"
	>
		<uc-arrow-left-svg />
		Close Sidebar
	</button>

	<div
		class="px-3 pb-4 text-sm text-gray-500 mb-[calc(var(--sidebar-fixed-menu)_+_20px)]"
	>
		<ng-container #content></ng-container>
	</div>
</aside>
<!-- Sidebar fixed menu -->
<div
	#fixedMenu
	class="fixed bottom-0 left-0 z-[102] w-64 mt-auto transition-all duration-150 bg-white border-t border-r"
	[ngClass]="{ 'translate-x-0': isOpen(), '-translate-x-full': !isOpen() }"
>
	<ul class="grid gap-2 px-3 my-4 font-medium text-gray-900">
		<ng-container #fixedMenuContent></ng-container>
		<li><a routerLink="/users">Users & Permissions</a></li>
		<li><button (click)="onLogout()" type="button">Log Out</button></li>
	</ul>
</div>
<div
	(click)="onClose()"
	class="fixed inset-0 w-screen h-screen bg-gray-900 cursor-pointer md:hidden"
	[ngClass]="{
		'z-[101] opacity-90': isOpen(),
		'-z-50 opacity-0': !isOpen(),
	}"
></div>
