import { Component, input, output } from '@angular/core';

@Component({
	selector: 'cms-options-dropdown-single-select',
	standalone: true,
	imports: [],
	templateUrl: './options-dropdown-single-select.component.html',
})
export class OptionsDropdownSingleSelectComponent<T> {
	value = input.required<T>();
	label = input.required<string>();
	optionSelected = output<T>();

	onSelectSort(value: T) {
		this.optionSelected.emit(value);
	}
}
