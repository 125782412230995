<label [attr.for]="optionId()" class="block py-2 whitespace-nowrap leading-[21px]">
	<input
		type="checkbox"
		[checked]="checked()"
		[attr.name]="optionId()"
		[attr.id]="optionId()"
		(change)="onChange(value())"
		class="border-gray-300 rounded-sm bg-gray-50"
	/>
	<span class="ml-2 text-sm font-medium text-gray-900 capitalize">
		{{ label() }}
	</span>
</label>
