<div class="relative" ucDropdown elementId="sort">
	<button
		type="button"
		class="flex justify-center items-center border rounded-lg px-5 py-2.5 text-gray-800 gap-2 w-full sm:w-auto"
	>
		{{ label() }}
		<uc-arrow-down-svg />
	</button>
	<div
		class="absolute left-0 px-4 py-2 bg-white border rounded-lg w-full sm:min-w-[195px] mt-4 dropdown-content"
	>
		<ng-content></ng-content>
	</div>
</div>
