<div class="flex items-center gap-1 bg-gray-100 rounded-md py-0.5 px-3">
	<ng-content></ng-content>
	<span class="text-gray-900 text-sm font-medium leading-[21px] capitalize">{{
		label()
	}}</span>
	@if (allowDelete()) {
		<button
			class="text-xl font-light text-gray-500 block -mt-[3px] leading-[21px]"
			(click)="onRemove.emit(filterId())"
		>
			&times;
		</button>
	}
</div>
