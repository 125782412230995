<div class="px-3 py-4 md:hidden">
	<div class="flex justify-between">
		<p class="text-lg font-semibold">Main Menu</p>
		<button (click)="closeSidebar.emit()" type="button">
			<uc-close-modal-svg customStyles="w-3 h-3" />
		</button>
	</div>

	<div ucDropdown class="relative mt-4">
		<button
			id="dropdownMenu"
			class="text-gray-900 text-sm bg-gray-50 focus:outline-none font-medium rounded-lg px-5 py-2.5 text-center inline-flex items-center justify-between border border-gray-300 w-full"
			type="button"
		>
			{{ selected }}
			<uc-arrow-down-svg customStyles="w-6 h-6" />
		</button>

		<!-- Dropdown menu -->
		<div
			class="absolute left-0 w-full bg-white divide-y divide-gray-100 rounded-lg shadow-md dropdown-content top-12"
		>
			<ul class="py-2 text-sm text-gray-700" aria-labelledby="dropdownMenu">
				<li (click)="onSelected('Content/SEO')">
					<a routerLink="/content" class="block px-4 py-2 hover:bg-gray-100"
						>Content/SEO</a
					>
				</li>
				<li (click)="onSelected('Client Success')">
					<a
						routerLink="/client-success"
						class="block px-4 py-2 hover:bg-gray-100"
						>Client Success</a
					>
				</li>
				<li (click)="onSelected('Campaigns')">
					<a routerLink="/campaigns" class="block px-4 py-2 hover:bg-gray-100"
						>Campaigns</a
					>
				</li>
				<li (click)="onSelected('Company Analytics')">
					<a routerLink="/analytics" class="block px-4 py-2 hover:bg-gray-100"
						>Company Analytics</a
					>
				</li>
			</ul>
		</div>
	</div>
</div>
