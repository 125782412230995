import { NgClass } from '@angular/common';
import {
	Component,
	effect,
	ElementRef,
	HostListener,
	input,
	OnInit,
	output,
	Renderer2,
	signal,
	untracked,
	viewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { ArrowDownSvgComponent, SearchSvgComponent } from '@uc/shared/svg';

@Component({
	selector: 'cms-dropdown-search',
	standalone: true,
	imports: [FormsModule, SearchSvgComponent, ArrowDownSvgComponent, NgClass],
	templateUrl: './dropdown-search.component.html',
})
export class DropdownSearchComponent implements OnInit {
	items = input.required<{ id: string; name: string }[]>();
	label = input.required<string>();
	value = input<string | null>(null);

	select = output<{ id: string; name: string }>();

	protected button = viewChild.required<ElementRef<HTMLButtonElement>>('button');
	protected options = viewChild<ElementRef<HTMLDivElement>>('options');

	protected selectedItem = signal<{ id: string; name: string } | null>(null);
	protected showOptions = signal(false);
	protected filteredItems: { id: string; name: string }[] = [];
	protected focusedValueIndex = 0;
	protected id = uuidv4();
	protected searchTerm = '';

	@HostListener('document:click', ['$event'])
	clickout(event: MouseEvent) {
		if (!this._elRef.nativeElement.contains(event.target)) {
			this._hideOptions();
		}
	}

	constructor(
		private _renderer: Renderer2,
		private _elRef: ElementRef,
	) {
		effect(() => {
			if (this.value()) {
				const item = this.items()?.find((item) => item.id === this.value());
				if (item) {
					untracked(() => {
						this.selectedItem.set(item);
					});
					this.focusedValueIndex = this.items().findIndex(
						(item) => item.id === this.value(),
					);
				}
			}
		});
	}

	ngOnInit(): void {
		this.filteredItems = [...this.items()];
	}

	protected onSearch() {
		this.filteredItems = this.items()?.filter((item) =>
			item.name.toLowerCase().includes(this.searchTerm.toLowerCase()),
		);
	}

	protected onSelectItem(item: { id: string; name: string }, index?: number) {
		if (!item) return;
		this.selectedItem.set(item);
		this.select.emit(item);
		this.searchTerm = '';
		if (index) {
			this.focusedValueIndex = index;
		}
		this._hideOptions();
	}

	protected onClick() {
		this.showOptions.set(!this.showOptions());
		const element = this.options()?.nativeElement;
		if (!element) return;

		if (!this.showOptions()) {
			this._renderer.addClass(element, 'hidden');
			this._renderer.setStyle(element, 'transform', 'translateY(0px)');
			return;
		}
		const input = element.querySelector('input');
		setTimeout(() => input?.focus(), 0);

		this.filteredItems = [...this.items()];

		this._renderer.removeClass(element, 'hidden');
		this._renderer.removeStyle(element, 'width');
		this._renderer.setStyle(element, 'transform', `translateY(0px)`);

		this._applyTranslate();
		this._scrollOptionIntoView();
	}

	protected onKeyDown(event: KeyboardEvent) {
		switch (event.key) {
			case 'ArrowDown':
				this._focusNext();
				break;
			case 'ArrowUp':
				this._focusPrev();
				break;
			case 'Enter':
				this.onSelectItem(this.filteredItems[this.focusedValueIndex]);
				break;
			case 'Escape':
				this._hideOptions();
				break;
		}
	}

	private _focusNext() {
		this.focusedValueIndex = Math.min(
			this.focusedValueIndex + 1,
			this.filteredItems.length - 1,
		);
		this._scrollOptionIntoView();
	}

	private _focusPrev() {
		this.focusedValueIndex = Math.max(this.focusedValueIndex - 1, 0);
		this._scrollOptionIntoView();
	}

	private _scrollOptionIntoView(): void {
		this.focusedValueIndex ??= 0;
		const elements = this.options()?.nativeElement.querySelectorAll('li');

		if (!elements) return;
		const elementsArray = Array.from(elements);

		const option = elementsArray.filter((_, i) => this.focusedValueIndex === i);
		option[0]?.scrollIntoView({ block: 'nearest' });
	}

	private _calculatePositionX() {
		const optionsElem = this.options()?.nativeElement;
		const buttonElem = this.button().nativeElement;
		if (!optionsElem) return 0;

		const buttonRect = buttonElem.getBoundingClientRect();
		const optionsRect = optionsElem?.getBoundingClientRect();
		const viewportWidth = window.innerWidth;
		const isGreaterThanViewport = optionsRect.width > viewportWidth;
		const offsetX = 16;

		this._renderer.setStyle(optionsElem, 'width', `${optionsRect.width}px`);

		if (isGreaterThanViewport) {
			const width = viewportWidth - offsetX * 2;
			this._renderer.setStyle(optionsElem, 'width', `${width}px`);
			return -Math.abs(optionsRect.left - offsetX);
		}

		const isOverflowingRight = optionsRect.right > viewportWidth;
		if (isOverflowingRight) {
			return -Math.abs(
				buttonRect.left + optionsRect.width - viewportWidth + offsetX,
			);
		}

		return buttonRect.left - optionsRect.left;
	}

	private _applyTranslate() {
		const optionsElem = this.options()?.nativeElement;
		const buttonElem = this.button().nativeElement;
		if (!optionsElem) return;

		const offsetY = 15;
		const buttonRect = buttonElem.getBoundingClientRect();
		const optionsRect = optionsElem.getBoundingClientRect();

		const positionX = this._calculatePositionX();
		const positionY = buttonRect.bottom - optionsRect.top + offsetY;
		this._renderer.setStyle(
			optionsElem,
			'transform',
			`translate(${positionX}px, ${positionY}px)`,
		);

		const position =
			window.innerHeight < optionsRect.bottom + positionY ? 'top' : 'bottom';
		if (position === 'top') {
			const positionY = optionsRect.bottom - buttonRect.top + offsetY;
			this._renderer.setStyle(
				optionsElem,
				'transform',
				`translate(${positionX}px, -${positionY}px)`,
			);
		}
	}

	private _hideOptions() {
		const element = this.options()?.nativeElement;
		this._renderer.addClass(element, 'hidden');
		this.showOptions.set(false);
	}
}
